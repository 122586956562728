//
// Helpers
//

// const { response } = require("express");

function $(selector, context) {
	return (context || document).querySelector(selector);
}

function $All(selector, context) {
	var arr = [];
	arr.push.apply(arr, (context || document).querySelectorAll(selector));
	return arr;
}


//
// forEach Polyfill
// https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach
//
if (window.NodeList && !NodeList.prototype.forEach) {
	NodeList.prototype.forEach = function (callback, thisArg) {
		thisArg = thisArg || window;
		for (var i = 0; i < this.length; i++) {
			callback.call(thisArg, this[i], i, this);
		}
	};
}


//
// Element.closest() Polyfill
// https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
//
if (!Element.prototype.matches) {
	Element.prototype.matches =
	Element.prototype.msMatchesSelector ||
	Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
	Element.prototype.closest = function(s) {
	var el = this;

	do {
		if (Element.prototype.matches.call(el, s)) return el;
		el = el.parentElement || el.parentNode;
	} while (el !== null && el.nodeType === 1);
	return null;
	};
}

//
// Element.remove() Polyfill
// https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/remove()/remove().md
//
(function (arr) {
	arr.forEach(function (item) {
	  if (item.hasOwnProperty('remove')) {
		return;
	  }
	  Object.defineProperty(item, 'remove', {
		configurable: true,
		enumerable: true,
		writable: true,
		value: function remove() {
		  if (this.parentNode !== null)
			this.parentNode.removeChild(this);
		}
	  });
	});
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);


//
// Video and audio tracking
//
/** @type {HTMLMediaElement[]} */
const medias = [];
[].push.apply(medias, document.querySelectorAll('video,audio'));

// Track every x seconds
const trackInterval = 30;

medias.forEach(media => {
    const type = media.tagName.toLowerCase();
    let duration = 0;

    // Track play of media (note: this will track media twice if it was paused)
    media.addEventListener('play', () => {
        if (window._paq) {
            duration = Math.floor(media.currentTime / trackInterval) * trackInterval;
            _paq.push(['trackEvent', type, `Play ${type}`, media.currentSrc]);
        }
    }, false);

    // Track pause of media
    media.addEventListener('pause', () => {
        if (window._paq && !media.ended) {
            _paq.push(['trackEvent', type, `Pause ${type}`, media.currentSrc]);
        }
    }, false);

    // Track when media was played till the end
    media.addEventListener('ended', () => {
        if (window._paq) {
            _paq.push(['trackEvent', type, `End ${type}`, media.currentSrc]);
        }
    }, false);

    // Track time played every x seconds (see "trackInterval")
    media.addEventListener('timeupdate', () => {
        if (window._paq) {
            const currentDuration = Math.floor(media.currentTime / trackInterval) * trackInterval;
            if (currentDuration > duration) {
                const seconds = currentDuration % 60;
                const minutes = Math.floor(currentDuration / 60);
                const hours = Math.floor(currentDuration / 3600);
                const durationString = `${hours}:${`0${minutes}`.substr(-2)}:${`0${seconds}`.substr(-2)}`;
                _paq.push(['trackEvent', type, `Time played ${type} ${durationString}`, media.currentSrc]);
                duration = currentDuration;
            }
        }
    }, false);
});


//
// Get CSS Breakpoints Value
//
var breakpoint = {};
breakpoint.refreshValue = function () {
	this.value = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
};
window.addEventListener('resize', function(){
	breakpoint.refreshValue();
});
breakpoint.refreshValue();


//
// IE detection
// returns version of IE or false, if browser is not Internet Explorer
//
function detectIE() {
	var ua = window.navigator.userAgent;

	var msie = ua.indexOf('MSIE ');
	if (msie > 0) {
		// IE 10 or older => return version number
		return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
	}

	var trident = ua.indexOf('Trident/');
	if (trident > 0) {
		// IE 11 => return version number
		var rv = ua.indexOf('rv:');
		return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
	}

	var edge = ua.indexOf('Edge/');
	if (edge > 0) {
		// Edge (IE 12+) => return version number
		return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
	}

	// other browser
	return false;
}

if (detectIE() != false){
	if( detectIE() == 16 ) {
		document.documentElement.classList.add('msie edge-16');
	}
	else if( detectIE() >= 17 ) {
		document.documentElement.classList.add('no-msie edge-17+');
	}
	else {
		document.documentElement.classList.add('msie');
	}
} else{
	document.documentElement.classList.add('no-msie');
}


//
// Remove .no-js class from html element if javascript is enabled
//
document.documentElement.className = document.documentElement.className.replace('no-js','js');



//
// Test for touchevent support and if not supported, attach .no-touch class to the HTML tag.
// https://www.prowebdesign.ro/how-to-deal-with-hover-on-touch-screen-devices/
//
if (!('ontouchstart' in document.documentElement)) {
	document.documentElement.className += ' no-touch';
}

blogpostCollapse();

function blogpostCollapse(){

	//classes
	let c = {
		toggle: 'js-blogpost__readmore',
		collapse: 'js-blogpost__collapse',
		collapseOpenClass: 'blogpost__collapsed-content--open',
		toggleHiddenClass: 'blogpost__readmore--hidden'
	};

	//selectors
	let s = {
		blog: '.js-blog',
		toggle: '.' + c.toggle,
		collapse: '.' + c.collapse
	};

	let blog = document.querySelector(s.blog)
	let toggles = document.querySelectorAll(s.toggle);

	if (blog) {
		blog.addEventListener('click', (event) => {

			let toggle = event.target;
			if (toggle.classList.contains(c.toggle)) {
				event.preventDefault();
				let collapse = toggle.parentElement.parentElement.querySelector(s.collapse);
				// show content if collapsed content is available
				if (collapse.classList.contains(c.collapse)) {
					collapse.classList.add(c.collapseOpenClass);
					collapse.setAttribute('aria-hidden', false);
					collapse.tabIndex = 0;
					collapse.focus();
				}
				//hide button
				toggle.setAttribute('aria-expanded', 'true');
				toggle.disabled = true;
				toggle.tabIndex = -1;
				toggle.classList.add(c.toggleHiddenClass);
			}

		})
	}

	// only needed for posts which are not inside .js-blog eg. tester-page
	toggles.forEach( toggle => {
		toggle.addEventListener('click', event => {

			event.preventDefault();
			event.stopPropagation(); // no double execution when child of .js-log
			let collapse = toggle.parentElement.parentElement.querySelector(s.collapse);
			// show content if collapsed content is available
			if (collapse.classList.contains(c.collapse)) {
				collapse.classList.add(c.collapseOpenClass);
				collapse.setAttribute('aria-hidden', false);
				collapse.tabIndex = 0;
				collapse.focus();
			}
			//hide button
			toggle.setAttribute('aria-expanded', 'true');
			toggle.disabled = true;
			toggle.tabIndex = -1;
			toggle.classList.add(c.toggleHiddenClass);

		});
	});
}

var mainMenu 	 = document.querySelector('.js-menu');
var mainMenuToggleLinks = $All('.js-toggle-menu__item > .js-disabled-link');
var mainMenuDisabledLinks = $All('.js-disabled-link');
var mainMenuToggleInput = document.querySelector('.js-showmenu__input');
var hash = window.location.hash;


//
// Open Desktop Overlay Menu Items
//
mainMenuToggleLinks.forEach(function(el) {
	el.addEventListener('click', function(event) {
		event.preventDefault();

		var openItem = document.querySelector('.js-toggle-menu__listitem.is-open');

		if (el.parentNode.classList.contains('is-open')) {
			el.parentNode.classList.remove('is-open');
		} else {
			if (openItem !== null) {
				openItem.classList.remove('is-open');
			}

			// close all other open Menus
			mainMenuToggleLinks.forEach(function(openElements) {
				openElements.parentNode.classList.remove('is-open');
			});

			el.parentNode.classList.add('is-open');
		}
	});
});



//
// Detect Click outside of Overlay Menu
//
document.addEventListener('click', function(event) {
	if (event.target.closest('.js-toggle-menu__item, .submenu')) return;

	var openItem = document.querySelector('.js-toggle-menu__item.is-open');
	if (openItem !== null) {
		openItem.classList.remove('is-open');
	}
});



//
// Detect ESC Key to close Overlay Menu
//
document.addEventListener('keyup', function(event) {
	var key = event.which || event.keyCode;
	if (key === 27) {
		var openItem = document.querySelector('.js-toggle-menu__item.is-open');
		if (openItem !== null) {
			openItem.classList.remove('is-open');
		}
	}
});



//
// Close Mobile Menu on window.resize
//
window.addEventListener('resize', function(){
	if (mainMenuToggleInput !== null && (mainMenuToggleInput.checked === true) ) {
		mainMenuToggleInput.checked = false;
	}
});



//
// Open Desktop Overlay Menu Items
//
mainMenuDisabledLinks.forEach(function(link) {
	link.addEventListener('click', function(event) {
		event.preventDefault();
	});
});



//
// Map Overlay Marker Hover
//
const $mapText   = $All('.js-map__text');
const $mapMarker = $All('.js-map__marker');

$mapText.forEach(function(el, i){
	const hoverImage = document.getElementById(el.getAttribute('data-overlay'));

	el.addEventListener('mouseenter', function(e) {
		const number = el.getAttribute('data-number');
		$mapMarker[i].classList.add('hover');

		if (hoverImage !== null) {
			hoverImage.classList.add('active');
		}
	});

	el.addEventListener('mouseleave', function(e) {
		$mapMarker[i].classList.remove('hover');

		if (hoverImage !== null) {
			hoverImage.classList.remove('active');
		}
	});
});

$mapMarker.forEach(function(el, i){
	const hoverImage = document.getElementById(el.getAttribute('data-overlay'));

	el.addEventListener('mouseenter', function(e) {
		$mapText[i].classList.add('hover');

		if (hoverImage !== null) {
			hoverImage.classList.add('active');
		}
	});

	el.addEventListener('mouseleave', function(e) {
		$mapText[i].classList.remove('hover');

		if (hoverImage !== null) {
			hoverImage.classList.remove('active');
		}
	});
});





//
// Accordion
//
var accordion 		  = $All('.js-accordion');
var accordionButton   = $All('.js-accordion__button');
var accordionCollapse = $All('.js-accordion__collapse');

function toggleAccordionItem(el) {
	var isopen  = el.getAttribute('aria-expanded');
	var parent  = el.parentNode.parentNode;
	var title   = parent.querySelector('.accordion__title, .timeline__title');
	var content = parent.querySelector('.accordion__collapse, .timeline__collapse');

	if (isopen == 'false') {
		el.setAttribute('aria-expanded', true);
		parent.classList.add('is--open');
		title.classList.add('is--open');
		el.blur();

		content.style.height = 'auto';
		var height = content.clientHeight + 'px';
		content.style.height = '0px';

		setTimeout(function () {
			content.style.height = height;
			setTimelineImages(parent);

			content.addEventListener('transitionend', function () {
				if (parent.querySelector('.js-timeline__media') !== null) {
					parent.querySelector('.js-timeline__media .image').style.opacity = '1';
				}
			}, {
				once: true
			});
		}, 0);
	} else if (parent.querySelector('.js-timeline__media') !== null) {
		parent.querySelector('.js-timeline__media .image').style.opacity = '0';

		content.style.height = '0px';
		parent.parentNode.style.minHeight = '0';

		setTimeout(function () {
			el.setAttribute('aria-expanded', false);
			el.blur();
			parent.classList.remove('is--open');
			title.classList.remove('is--open');
		}, 500);
	} else {
		content.style.height = '0px';
		content.addEventListener('transitionend', function () {
			el.setAttribute('aria-expanded', false);
			el.blur();
			parent.classList.remove('is--open');
			title.classList.remove('is--open');
		}, {
			once: true
		});
	}
}

if (accordion.length > 0) {
	accordionButton.forEach(function(el){
		el.addEventListener('click', function(e) {
			e.preventDefault();
			toggleAccordionItem(el);
		});
	});


	// check if hash has changed
	function locationHashChanged() {
		const el = document.getElementById(location.hash.substring(1));
		if (el && el.classList.contains('js-accordion__button')) {
			toggleAccordionItem(el);
		}
	}
	window.onhashchange = locationHashChanged;


	// check if uri has hash
	if (hash != '') {
		const el = document.getElementById(hash.substring(1));
		if(el && el.classList.contains('js-accordion__button')) {
			toggleAccordionItem(el);
		}
	}
}



//
// Timeline
//
const timelinewrapper = document.querySelector('.js-timelinewrapper');
const timeline = $All('.js-timeline');
const verticalline = $All('.js-timeline__verticalline');
const timelineAndMilestones = $All('.js-timeline, .js-timeline__milestone');

function setTimelineImages(parent) {
	if( parent.getElementsByClassName('js-timeline__media')[0] != null && breakpoint.value >= 800 ){
		const timelineItem = parent.parentNode;
		const imagewrapper = timelineItem.getElementsByClassName('js-timeline__media')[0];
		const image = imagewrapper.getElementsByClassName('image')[0];
		const buttonHeight = timelineItem.getElementsByClassName('timeline__title')[0].clientHeight;
		const imagewrapperHeight = imagewrapper.clientHeight;

		timelineItem.style.minHeight = imagewrapperHeight + buttonHeight + 'px';
		imagewrapper.style.top = buttonHeight + 'px';
		imagewrapper.style.height = imagewrapperHeight + 'px';
	}
}

function setTimelineImagesOnResize() {
	const openAccordion = $All('.js-accordion.is--open');
	if(openAccordion != null){
		openAccordion.forEach(function(el){
			if(el.getElementsByClassName('js-timeline__media')[0] != null){
				if(breakpoint.value < 800) {
					el.parentNode.style.minHeight = 'auto';
					el.getElementsByClassName('js-timeline__media')[0].style.cssText = 'top: auto; height: auto;';
					el.getElementsByClassName('js-accordion__collapse')[0].style.height = 'auto';
				} else {
					const imagewrapper = el.getElementsByClassName('js-timeline__media')[0];
					const imagewrapperHeight = imagewrapper.clientHeight;
					const buttonHeight = el.getElementsByClassName('timeline__title')[0].clientHeight;

					el.parentNode.style.minHeight = imagewrapperHeight + buttonHeight + 'px';
					el.getElementsByClassName('image')[0].classList.add('is--visible');
				}
			}
		});
	}
}



function startTimeline() {
	const counterTimelines = timeline.length;
	const firstButtonHeight = timeline[0].getElementsByClassName('timeline__title')[0].clientHeight;
	const timelinewrapperHeight = timelinewrapper.clientHeight;
	const verticallinePosY = ((firstButtonHeight / 2) - 2);
	const lastTimelineItemHeight = timelinewrapper.getElementsByClassName('js-timeline')[counterTimelines - 1].clientHeight;
	let verticallineHeight = timelinewrapperHeight - verticallinePosY;

	// Open item on Page Load
	timeline.forEach(function(el){
		if(el.classList.contains('is--openOnPageload')) {
			toggleAccordionItem(el.getElementsByClassName('js-accordion__button')[0]);
			el.classList.remove('is--openOnPageload');
		}
	});

	if (breakpoint.value >= 800) {
		// verticallineHeight = '100%';
		verticalline[0].style.top = verticallinePosY + 'px';
		verticallineHeight = 'calc(100% - ' + lastTimelineItemHeight + 'px)';
	} else {
		verticalline[0].style.top = '-' + verticallinePosY + 'px';
		verticallineHeight = 'calc(100% + 42px - 80px)';
	}


	if (verticalline[0].classList.contains('is--hidden')) {
		// anime.js Vertical Line Animation
		anime({
			targets: '.js-timeline__verticalline.is--hidden',
			height: verticallineHeight,
			duration: 2000,
			easing: 'easeInOutExpo',
			complete: function() {
				verticalline[0].classList.remove('is--hidden')
				// verticalline[0].style.height = 'calc(100% - ' + verticallinePosY + 'px - ' + lastTimelineItemHeight + 'px)';
			}
		});

		// anime.js timeline
		let animeTimeline = anime.timeline({
			easing: 'easeOutExpo',
			duration: 500
		});

		timelineAndMilestones.forEach(function(el){
			animeTimeline.add({
				targets: el,
				opacity: 1,
				complete: function() {
					if(breakpoint.value >= 800) {
						if(!el.classList.contains('js-timeline__milestone')) {
							const branch = el.getElementsByClassName('timeline__branch')[0];
							anime({
								targets: branch,
								width: '50%',
								duration: 200,
								easing: 'easeInOutExpo',
							});
							timeline.forEach(function(el){
								const buttonHeight = el.getElementsByClassName('timeline__title')[0].clientHeight;
								const branch = el.getElementsByClassName('timeline__branch')[0];
								branch.style.top = ((buttonHeight / 2) - 2) + 'px';
							});
						}
					}
				}
			});
		});
	}
}



function updateTimelineOnResize() {
	const verticallinePosY = (timeline[0].getElementsByClassName('timeline__title')[0].clientHeight / 2);
	const timelineBranch = $All('.timeline__branch');

	if(breakpoint.value < 800) {
		timelineBranch.forEach(function(el){
			el.style.width = 0;
		});
		verticalline.forEach(function(el){
			el.style.top = '-' + verticallinePosY + 'px';
			el.style.height = 'calc(100% - 80px + ' + verticallinePosY + 'px)';
		});
	} else {
		timelineBranch.forEach(function(el){
			el.style.width = '50%';
		});
		verticalline.forEach(function(el){
			el.style.top = '-' + verticallinePosY + 'px';
			el.style.height = 'calc(100% - 80px)';
		});
	}

}


// Set Timeline Lines
if (timeline.length > 0) {
	if ('IntersectionObserver' in window) {
		function handleIntersection(entries) {
			entries.forEach(function(entry){
				if (entry.isIntersecting) {
					startTimeline();
				}
			});
		}
		const observer = new IntersectionObserver(handleIntersection, {
			threshold: 0.3,
		});
		observer.observe(timelinewrapper);
	} else {
		startTimeline();
	}
}

// Set Timeline Lines on Window Resize
window.addEventListener('resize', function(){
	if (timeline.length > 0) {
		setTimelineImagesOnResize();
		updateTimelineOnResize();
	}
});



//
// Carousel
//
var carousel 		 = $All('.js-carousel');
var carouselCollapse = $All('.js-carousel__collapse');
var carouselMedia    = $All('.js-carousel__media');

// Close all Elements at all Carousels
carouselCollapse.forEach(function(el){
	if(breakpoint.value >= 800 ) {
		el.setAttribute('data-height', el.getBoundingClientRect().height+'px');
	} else {
		el.setAttribute('data-height', 'auto');
	}
	el.style.height = 0;
});

// Open first Element at each Carousel
carousel.forEach(function(carouselElement){
	var FirstElementHeight = carouselElement.getElementsByClassName('js-carousel__collapse')[0].getAttribute('data-height');
	carouselElement.getElementsByClassName('js-carousel__collapse')[0].style.height = FirstElementHeight;
	carouselElement.getElementsByClassName('js-carousel__collapse')[0].setAttribute('aria-expanded', true);
	carouselElement.getElementsByClassName('js-carousel__title')[0].classList.add('is--open');
	carouselElement.getElementsByClassName('js-carousel__media')[0].setAttribute('style', 'opacity: 1; visibility: visible;');

	function openCarouselItem(el, i) {
		var parentList  = el.parentNode.parentNode;
		var parentCarousel = el.parentNode.parentNode.parentNode.parentNode;
		var elementHeight;

		if(breakpoint.value >= 800 ) {
			elementHeight = el.nextElementSibling.getAttribute('data-height');
		} else {
			var elementMediaHeight = el.nextElementSibling.getElementsByClassName('carousel__media')[0].getBoundingClientRect().height;
			var elementContentHeight = el.nextElementSibling.getElementsByClassName('carousel__content')[0].getBoundingClientRect().height;
			elementHeight = elementMediaHeight + elementContentHeight;
		}

		parentList.querySelectorAll('.js-carousel__collapse').forEach(function(el){
			el.style.height = 0;
		});

		parentList.querySelectorAll('.js-carousel__title').forEach(function(el){
			el.classList.remove('is--open');
		});

		parentCarousel.querySelectorAll('.js-carousel__media').forEach(function(el){
			el.setAttribute('style', 'opacity: 0; visibility: hidden;');
		});

		el.nextElementSibling.setAttribute('aria-expanded', true);
		el.nextElementSibling.setAttribute('style','height:'+elementHeight);
		el.classList.add('is--open');
		parentCarousel.getElementsByClassName('js-carousel__media')[i].setAttribute('style', 'opacity: 1; visibility: visible;');
	}

	carouselElement.querySelectorAll('.js-carousel__title').forEach(function(el, i){
		el.addEventListener('click', function(e) {
			e.preventDefault();
			openCarouselItem(el, i);
			el.blur();
		});

		el.addEventListener('keypress', function(e) {
			var key = e.which || e.keyCode;
			if (key === 13) {
				openCarouselItem(el, i);
			}
		});
	});
});



//
// Pause all playing Videos
//
function pauseAllVideos() {
	var video = $All('video');
	var youtube = $All('.js-video.is--playing');

	if (video.length > 0) {
		video.forEach(function(el){
			el.pause();
		});
	}
}



//
// Special Videoplayer with Autostart Video
//
function playFullVideo(e) {
	pauseAllVideos();

	if (e.target.classList.contains('js-videobuttonwrapper')) {
		var videoteaser = e.target.previousElementSibling;
		var videofull   = e.target.previousElementSibling.previousElementSibling;
		e.target.remove();
	} else if (e.target.classList.contains('js-videobutton')) {
		var videoteaser = e.target.closest('.js-videoplayerautostart').querySelector('.js-videoteaser');
		var videofull   = e.target.closest('.js-videoplayerautostart').querySelector('.js-videofull');
		e.target.parentNode.remove();
	} else {
		console.log('Error playFullVideo.');
	}

	videoteaser.pause();
	videoteaser.removeAttribute('loop');
	videoteaser.removeAttribute('autoplay');
	videoteaser.classList.add('is--hidden');
	videofull.classList.remove('is--hidden');
	videofull.play();
}


var autostartVideoPlayer = $All('.js-videoplayerautostart');

autostartVideoPlayer.forEach(function(video){
	video.querySelector('.js-videobuttonwrapper').addEventListener('click', function(event) {
		if (video.querySelector('.js-videofull') != null) {
			playFullVideo(event, true);
		} else if (video.querySelector('.js-externalvideo') != null) {
			pasteVideoPlayer(event, true);
		} else {
			console.log('Error autostartVideoPlayer');
		}
	});

	video.querySelector('.js-videobuttonwrapper').addEventListener('keydown', function(event) {
		var key = event.which || event.keyCode;
		if (key === 13 || key === 32) {
			if(video.querySelector('.js-videofull') != null) {
				playFullVideo(event, true);
			} else if (video.querySelector('.js-externalvideo') != null) {
				pasteVideoPlayer(event, true);
			} else {
				console.log('Error autostartVideoPlayer');
			}
		}
	});
});


//
// Paste Video Player (Youtube, Vimeo or own html5 video)
//
function pasteVideoPlayer(e, videoPlaying) {
	e.preventDefault();
	e.stopPropagation();
	var videoplayer;
	var cookieplaceholder;

	pauseAllVideos();

	if (videoPlaying === false && e.target.classList.contains('js-videobuttonwrapper')) {
		// console.log('js-videobuttonwrapper click');
		videoplayer = e.target.closest('.js-video');
		cookieplaceholder = videoplayer.querySelector('.js-cookieplaceholder');
	} else if (videoPlaying === false && e.target.classList.contains('js-videobutton')) {
		// console.log('js-videobutton click');
		videoplayer = e.target.closest('.js-video');
		cookieplaceholder = videoplayer.querySelector('.js-cookieplaceholder');
	} else if (videoPlaying === true && (e.target.classList.contains('js-videobuttonwrapper') || e.target.classList.contains('js-videobutton'))) {
		// console.log('js-videobuttonwrapper or js-videobutton click @ Autoplay-Teaser');
		var videoContainer = e.target.closest('.js-videoplayerautostart');
		var videoteaser = videoContainer.querySelector('.js-videoteaser');
		videoteaser.pause();
		videoteaser.removeAttribute('loop');
		videoteaser.removeAttribute('autoplay');
		videoteaser.classList.add('is--hidden');
		videoplayer = videoContainer.querySelector('.js-externalvideo');
		videoContainer.querySelector('.js-videobuttonwrapper').remove();
		cookieplaceholder = videoContainer.querySelector('.js-cookieplaceholder');
	} else {
		console.log('Error pasting external Video.');
	}

	var ytID = videoplayer.getAttribute('data-youtube'),
		vimeoID = videoplayer.getAttribute('data-vimeo'),
		starttime = videoplayer.getAttribute('data-starttime') || 0,
		width   = videoplayer.offsetWidth,
		height  = videoplayer.offsetHeight,
		videoInner = videoplayer.querySelector('.video__inner'),
		src = false;

	if (ytID) {
		src = 'https://www.youtube-nocookie.com/embed/'+ ytID +'?rel=0&showinfo=0&autoplay=1&start='+ starttime;
	} else if (vimeoID) {
		src = 'https://player.vimeo.com/video/' + vimeoID + '?autoplay=1&color=ff0179#t='+ starttime +'s';
	} else {
		// Special Version for own html5 Videoplayer
		var html5video = videoplayer.querySelector('video');
		if (html5video){
			videoplayer.querySelector('.video__cover').remove();
			videoplayer.querySelector('.js-videobuttonwrapper').remove();
			html5video.play();
			html5video.setAttribute('tabindex', '0');
		} else {
			console.log('Error. Missing Video-ID.');
		}
	}

	if (src) {
		if (window.dwmCb && window.dwmCb.external) {
			cookieplaceholder && cookieplaceholder.remove();
			videoInner.innerHTML = '<iframe class="video__iframe" width="'+ width +'" height="'+ height +'" src="'+ src +'" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>';
			videoplayer.classList.toggle('is--playing');
		} else {
			cookieplaceholder && cookieplaceholder.classList.remove('is--hidden');
			document.addEventListener('dwm-cookies', function(e) {
				if (e.detail.external) {
					cookieplaceholder && cookieplaceholder.remove();
					videoInner.innerHTML = '<iframe class="video__iframe" width="'+ width +'" height="'+ height +'" src="'+ src +'" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>';
					videoplayer.classList.toggle('is--playing');
				}
			}, false);
		}
	}
}

var videoPlayer = $All('.js-video');

videoPlayer.forEach(function(video){
	video.querySelector('.js-videobuttonwrapper').addEventListener('click', function(event) {
		pasteVideoPlayer(event, false);
	});

	video.querySelector('.js-videobuttonwrapper').addEventListener('keydown', function(event) {
		var key = event.which || event.keyCode;
		if (key === 13 || key === 32) {
			pasteVideoPlayer(event, false);
		}
	});
});



//
// Videoplayer Start at current Time on Link click
//
var videoPlayerStarttimeLink = $All('a[href*="#"][href*="&"]');

function startVideoAtTimecode(el) {
	const parts = el.href.split('&');
	const targetVideo = parts[0].split('#')[1];
	const targetTime = parts[1];
	const videoPlayerContainer = document.getElementById(targetVideo);
	console.log('Starte Video #' + targetVideo + ' bei Sekunde ' + targetTime);

	const videoplayer = videoPlayerContainer.querySelector('.js-video');
	const  html5video = videoplayer.querySelector('video');

	if (html5video) {
		const buttonWrapper = videoPlayerContainer.querySelector('.js-videobuttonwrapper');
		if (buttonWrapper !== null){
			// Video wurde noch nicht gestartet
			// console.log('Play Button ist noch da.');
			videoplayer.querySelector('.video__cover').remove();
			buttonWrapper.remove();
		} else {
			// Video lief schon mal
			// console.log('Video wurde schon gestartet');
		}
		html5video.currentTime = parseFloat(targetTime);
		videoplayer.scrollIntoView(true);
		html5video.play();
		html5video.setAttribute('tabindex', '0');
	}
}

videoPlayerStarttimeLink.forEach(function(link){
	if (link.href.indexOf('#') < link.href.indexOf('&')) {
		link.addEventListener('click', function(event) {
			event.preventDefault();
			startVideoAtTimecode(link);
		});
	}
});



//
//
//
var videoPlayerDB = document.querySelectorAll('.js-db-youtube, .js-db-vimeo');

videoPlayerDB.forEach(function(video){
	video.querySelector('.js-videobutton').addEventListener('click', function(event) {
		// console.log(event.target);
		event.target.parentNode.parentNode.querySelector('.video__cover').remove();
		event.target.parentNode.remove();
	});

	video.querySelector('.js-videobuttonwrapper').addEventListener('click', function(event) {
		// console.log(event.target);
		if(!event.target.classList.contains('js-videobutton')) {
			event.target.parentNode.querySelector('.video__cover').remove();
			event.target.remove();
		}
	});
});



//
// Booking calendar
//
const calendars = $All('.js-booking-cal');
calendars.forEach(calendar => {
	const eventWrapper = calendar.querySelector('.booking-cal__eventwrapper');
	calendar.addEventListener('click', e => {
		const calendarEl = e.target.closest('.booking-cal');
		if (calendarEl !== null) {
			const target = e.target.closest('.js-booking-cal__day-number');
			eventWrapper.innerHTML = '';
			if (target !== null) {
				const events = target.querySelector('.booking-cal__events').cloneNode(true);
				eventWrapper.appendChild(events);
			}
		} else {
			const target = e.target.closest('.js-booking-cal__btn');
			if (target !== null) {
				$All('.booking-cal__eventitem').forEach(request => request.classList.remove('active'));
				target.parentNode.classList.add('active');
			}
		}
	}, false);
});

if (location.hash && location.hash.match(/^#?bcf-/)) {
	const form = document.querySelector(location.hash);
	if (form !== null) {
		form.closest('.booking-cal__eventitem').classList.add('always-active');
		const events = form.closest('.booking-cal__events').cloneNode(true);
		const eventWrapper = form.closest('.js-booking-cal').querySelector('.booking-cal__eventwrapper');
		eventWrapper.appendChild(events);
		form.removeAttribute('id');
	}
}



//
// Tag filters
//
const tags = $All('.js-tag')
if (tags.length > 0) {
	const events = $All('.event');
	tags.forEach(tag => {
		tag.addEventListener('change', e => {
			if (tag.id === '__all') {
				events.forEach(event => {
					event.classList.remove('hidden');
				});
			} else {
				const tagClass = `tag--${tag.value}`;
				events.forEach(event => {
					const eventClassList = event.classList;
					if (eventClassList.contains(tagClass)) {
						eventClassList.remove('hidden');
					} else {
						eventClassList.add('hidden');
					}
				});
			}
		}, false);
	});
}



//
// Slideshow
//
/**
 * Slideshow using tiny-slider
 * https://github.com/ganlanyuan/tiny-slider
*/
class TNSlideshow {
	/**
     * constructor for slideshow element
     *
     * @param   {NodeList}  elem	slideshow wrapper element
     *
     * @return  {ClassInstance}
     */
    constructor(slider) {

        this.s = {
			sliderWrapper: '.js-tns__wrapper',
            slide: '.js-tns__slide',
            prev: '.js-tns__prev',
            next: '.js-tns__next',
        };
        this.e = {
            slider: slider,
			sliderWrapper: slider.querySelector(this.s.sliderWrapper),
            slides: slider.querySelectorAll(this.s.slide),
            prev: slider.querySelector(this.s.prev),
            next: slider.querySelector(this.s.next),
        };
		this.options = {
			initClass: 'slideshow--init',
		};

        this.init();
        return this;

    }

    init() {
		if (this.e.slider.classList.contains(this.options.initClass)) {
			return;
		}
        this.initSlider();
		this.e.slider.classList.add(this.options.initClass);
    }

	/**
	 * Setup of slider. See
	 * https://github.com/ganlanyuan/tiny-slider
	 * for setup information
	 */
	initSlider() {

		this.slider = tns({
            container: this.e.sliderWrapper,
            items: 1,
            mouseDrag: true,
            nav: false,
			controls: true,
            prevButton: this.e.prev,
            nextButton: this.e.next,
            autoplay: false,
			arrowKeys: true,
            autoplayHoverPause: true,
            preventScrollOnTouch: 'auto',
			gutter: 40
        });

    }

}

/**
 * initSlideshows with option of target element in which to look for slidesows
 * Target Options used for dynamic loaded content eg. blogposts
 *
 * @param   {node}  target  will look for slideshows inside this target
 *
 */
function initTNSlideshows(target) {
	target = target || document;
    const s = {
        selectorSlideshows: '.js-tns'
    };
    const slideshows = document.querySelectorAll(s.selectorSlideshows);
    slideshows.forEach(function (slideshow) {
        new TNSlideshow(slideshow);
    });

}

initTNSlideshows();




//
// SVG Bugfix for IE 11
//
const svgItems = $All('.section .image__item svg');

function fixSvgSize(el) {
	const elAttrWidth  = el.getAttribute('width');
	const elAttrHeight = el.getAttribute('height');
	const elAttrRation = elAttrHeight / elAttrWidth;
	const elWidth  = el.getBoundingClientRect().width;
	const newHeight = Math.round(elWidth * elAttrRation) + 'px';
	el.style.height = newHeight;
}

if (document.documentElement.classList.contains('msie') && svgItems.length > 0) {
	svgItems.forEach(function(el){
		fixSvgSize(el);
	});

	window.addEventListener('resize', function(){
		svgItems.forEach(function(el){
			fixSvgSize(el);
		});
	});
}



//
// Change Cookie Settings
//
// https://gomakethings.com/how-to-update-localstorage-with-vanilla-javascript/
//
var cookieplaceholderSettingsButton = $All('.js-cookieplaceholder__button');

cookieplaceholderSettingsButton.forEach(function(button){
	button.addEventListener('click', function(event) {
		event.preventDefault();
		let existingStorageObject = localStorage.getItem('bannerStorage');
		existingStorageObject = existingStorageObject ? JSON.parse(existingStorageObject) : {};
		existingStorageObject.external = true;
		localStorage.setItem('bannerStorage', JSON.stringify(existingStorageObject));

		function setCookies(all, statistic, external) {
			const cookieEvent = document.createEvent('CustomEvent');
			cookieEvent.initCustomEvent('dwm-cookies', false, false, {
				all: all || (statistic && external),
				statistic,
				external,
			});
			document.dispatchEvent(cookieEvent);
		}

		setCookies(existingStorageObject.all, existingStorageObject.statistic, true);
	});
});



//
// DB Cookie Content Layer button in website footer
// https://consentlayer.bahn-x.de/quickstart#2-add-a-manage-consent-button-to-websites-footer
//
const cookieConsentMangerToogleButton = document.querySelectorAll('.dwm-cb-open-cookie-banner');
cookieConsentMangerToogleButton.forEach(function(button){
	button.addEventListener('click', function(event) {
		event.preventDefault();
		window.DB_CMP.showSecondLayer();
	});
});




//
// MANNHEIM - KARLSRUHE
//
//
// Revealing SVG graphic
//
function revealingSvgLine() {
	$All('svg.redthread__background line[data-stage-scrollstart]').forEach(function(el){
		var svg = el.closest('svg');
		var breakpoint = svg.getAttribute('data-breakpoint');
		var startMidHeight = 0;
		if(svg.classList.contains('start-mid-height')) {
			startMidHeight = el.getBoundingClientRect().height * 1.5;
		}
		var elementTop = el.getBoundingClientRect().top + document.body.scrollTop + startMidHeight;
		var viewportTop = document.body.scrollTop;
		var viewportBottom = viewportTop + window.innerHeight;
		var stageId = parseInt(el.getAttribute('data-stage-scrollstart'));


		if (elementTop < viewportTop){
			$All('svg.redthread__background[data-breakpoint="'+breakpoint+'"] g[data-stage="'+(stageId-1)+'"]').forEach(function(item){
				item.classList.add('visible', 'go', 'skip');
			});
			$All('svg.redthread__background[data-breakpoint="'+breakpoint+'"] g[data-stage="'+stageId+'"]').forEach(function(item){
				item.classList.add('visible', 'go');
			});
		} else if(elementTop > viewportTop && elementTop < viewportBottom){
			$All('svg.redthread__background[data-breakpoint="'+breakpoint+'"] g[data-stage="'+stageId+'"]').forEach(function(item){
				item.classList.add('visible');

				if (svg.classList.contains('with-video')) {
					svg.classList.remove('with-video')

					setTimeout(function() {
						$('.video-container video').play();
					}, 900);

					setTimeout(function() {
						$('.btn.first-step').classList.remove('hidden');
					}, 2500);
				}
			});
		}
	});
}


//
// Revealing SVG graphic only if its a good Browser
//
if (document.documentElement.classList.contains('no-msie')) {
	revealingSvgLine();

	window.addEventListener('scroll', function(e) {
		revealingSvgLine();
	});
}




var svgGroupItem = $All('svg.redthread__background g');
if (svgGroupItem !== null) {
	svgGroupItem.forEach(function(el){
		el.setAttribute('data-animation-count', 0);
	});
}




// Detect the End of Transition and Animation with the Help of JavaScript
// https://pineco.de/detect-the-end-of-transition-and-animation-with-the-help-of-javascript/
//
// Detecting CSS Animation Completion with JavaScript
// https://davidwalsh.name/css-animation-callback
//
function whichAnimationEvent() {
	var a;
	var el = document.createElement('fakeelement');
	var animations = {
		"animation" : "animationend",
		"oanimation" : "oanimationend",
		"msAnimation" : "msAnimationEnd",
		"webkitAnimation": "webkitAnimationEnd"
	}

	for (a in animations) {
		if (el.style[a] !== undefined) {
			return animations[a];
		}
	}
}


var svgGroupAnimate = $All('svg.redthread__background g .animate');
var animationEvent = whichAnimationEvent();

svgGroupAnimate.forEach(function(el){
	el.addEventListener(animationEvent, function(e){
		var currentCount = parseInt(el.closest('g').getAttribute('data-animation-count'))+1;
		var currentStage = parseInt(el.closest('g').getAttribute('data-stage'));
		var breakpoint = el.closest('svg').getAttribute('data-breakpoint');
		el.closest('g').setAttribute('data-animation-count', currentCount);

		if(currentCount >= el.closest('g').querySelectorAll('.animate').length) {
			$All('svg.redthread__background[data-breakpoint="'+breakpoint+'"]').forEach(function(item){
				item.querySelectorAll('g[data-stage="'+(currentStage+1)+'"]').forEach(function(element){
					element.classList.add('go');
				});
			});
		}
	});
});

function injectIframes() {
	$All('.js-iframe').forEach(function(iframeWrapper) {
		var iframe = document.createElement('iframe');
		iframe.className = 'iframe__iframe';
		iframe.src = iframeWrapper.getAttribute('data-src');
		iframeWrapper.replaceChild(iframe, iframeWrapper.firstElementChild)
	});
}

if (window.dwmCb && dwmCb.external) {
	injectIframes();
} else {
	document.addEventListener('dwm-cookies', function(e) {
		if (e.detail.external) {
			injectIframes();
		}
	}, false);
}

if($('.js-search')) {
	const input  = $('.js-search-input');
	const clear  = $('.js-search-clear');
	const tags 	 = $All('.js-search-tag');
	const tabs 	 = $All('.js-search-tab');
	const button = $('.search__field__search__button');
	const results = $('.js-search-results');
	const resultsWrapper = $('.search__results__items');
	const resultCategory = $All('.js-search-category');

	let activeTag;
	let activeTab;
	let tabsWithResults = [];
	let numPages;
	let	numEvents;
	let	numProjectEntries;
	let	numDownloads;
	let searchParam;

	const observer = new MutationObserver((mutations) => {
		for(let mutation of mutations) {
			if(mutation.type === 'attributes' && mutation.attributeName === 'class') {
				if(clear.classList.contains('is-visible')) {
					clear.setAttribute('tabindex', '0');
				} else {
					clear.setAttribute('tabindex', '-1');
				}
			}
		}
	});

	observer.observe(clear, { attributes: true });

	if (location.search && 'URLSearchParams' in window) {
		const searchParams = new URLSearchParams(location.search);
		if (searchParams.has('search')) {
			searchParam = searchParams.get('search');
		}
	}

	tags.forEach(element => {
		if (element.innerText === searchParam) {
			setActiveTag(element);
		}
		element.addEventListener('click', () => {
			setActiveTag(element);
		});

		element.addEventListener('keydown', (e) => {
			if (e.code === 'Enter') {
				setActiveTag(element);
			}
		});
	})

	function setActiveTag(element) {
		let newActiveTag = undefined;
		if (activeTag) {
			// Current tag == active tag
			if (element.classList.contains('is-active')) {
				input.value = '';
				clear.classList.remove('is-visible');
			}
			activeTag.classList.remove('is-active');
		}
		if (!activeTag || !element.classList.contains('is-active')) {
			newActiveTag = element;
			element.classList.add('is-active');
			input.value = element.innerHTML;
			clear.classList.add('is-visible');
		}
		activeTag = newActiveTag;
	}

	input.onkeydown = () => {
		if(input.value != '') {
			clear.classList.add('is-visible');
		} else {
			clear.classList.remove('is-visible');
		}
		if(activeTag) {
			if(input.value != activeTag.innerHTML) {
				activeTag.classList.remove('is-active');
			}
		}
	}

	input.onkeyup = () => {
		if(input.value != '') {
			clear.classList.add('is-visible');
		} else {
			clear.classList.remove('is-visible');
		}
		if(activeTag) {
			if(input.value != activeTag.innerHTML) {
				activeTag.classList.remove('is-active');
			}
		}
	}

	clear.addEventListener('click', () => {
		toggleClearButton();
	})

	clear.addEventListener('keydown', (e) => {
		if(e.code == 'Enter') {
			toggleClearButton();
		}
	})

	function toggleClearButton() {
		input.value = '';
		clear.classList.remove('is-visible');
		activeTag.classList.remove('is-active');
		activeTag = undefined;
		if($('.js-search-tag.is-active')) {
			$('.js-search-tag.is-active').classList.remove('is-active');
		}
	}

	function setNumberOfResults() {
		tabs.forEach((element) => {
			let text = '';
			if (element.dataset.results > 0) {
				element.classList.add('has-results');
				element.setAttribute('tabindex', '0');
			} else {
				element.classList.remove('has-results');
				element.setAttribute('tabindex', '-1');
			}
			if(element.innerHTML.includes("(")) {
				text = element.innerHTML.substring(0, element.innerHTML.indexOf("("));
				element.innerHTML = `${text} (${element.dataset.results})`;
			} else {
				element.innerHTML = `${element.innerHTML} (${element.dataset.results})`;
			}
			if(tabs.indexOf(element) == tabs.length - 1) {
				tabsWithResults = $All('.js-search-tab.has-results');
				tabsWithResults.forEach((element) => {
					element.addEventListener('click', () => {
						setActiveTabs(element);
						let type = '';
						if(element.classList.contains('page')) {
							type = "page";
						}
						if(element.classList.contains('event')) {
							type = "event";
						}
						if(element.classList.contains('projectEntry')) {
							type = "projectEntry";
						}
						if(element.classList.contains('download')) {
							type = "download";
						}

						resultCategory.forEach(elem => {
							if(elem.querySelector('div[class*="-content"]').innerHTML != "") {
								if(!elem.classList.contains(type)) {
									elem.classList.toggle('is-hidden');
								} else {
									elem.classList.remove('is-hidden');
								}
							}
						});
					})
					element.addEventListener('keydown', (e) => {
						if(e.code == 'Enter') {
							setActiveTabs(element);
							let type = '';
							if(element.classList.contains('page')) {
								type = "page";
							}
							if(element.classList.contains('event')) {
								type = "event";
							}
							if(element.classList.contains('projectEntry')) {
								type = "projectEntry";
							}
							if(element.classList.contains('download')) {
								type = "download";
							}

							resultCategory.forEach(elem => {
								if(elem.querySelector('div[class*="-content"]').innerHTML != "") {
									if(!elem.classList.contains(type)) {
										elem.classList.toggle('is-hidden');
									} else {
										elem.classList.remove('is-hidden');
									}
								}
							});
						}
					})
				})
			}
		})
	}

	function replaceNumOfResults(element) {
		let text = element.innerHTML;
		text.replace(text.substring(text.indexOf("(") + 1, text.indexOf(")")), element.dataset.results);
		return text;
	}


	function setActiveTabs(element) {
		if (activeTab !== element) {
			if (activeTab) {
				activeTab.classList.remove('is-active');
			}
			element.classList.add('is-active');
			activeTab = element;
		} else {
			activeTab.classList.remove('is-active');
			activeTab = undefined;
		}
	}

	/**
	 * Async search is temporarily disabled
	 *
	button.addEventListener('click', event => {
		event.preventDefault();
		resultsWrapper.querySelector('.js-loading').classList.add('loading');
		results.classList.remove('is-hidden');
		fetchData(input.value);
	})

	// Handle search results
	const testData = '[{"path": "/Dialog/Dialogforum und Workshops","title": "3. Dialogforum","text": "Die Mitglieder des Forums tauschen sich mit uns sowie den Planerinnen und Planern zu der Zugzahlungsprognose 2030, der Methodik zur Entwicklung von Linienkorridoren","downloads": [{"type": "PDF", "name": "3. Dialogforum Protokollentwurf"}], "link": "https://www.google.com", "type": "event"},{"path": "/Dialog/Dialogforum und Workshops","title": "2. Themenworkshop","text": "Die Mitglieder des Forums tauschen sich mit uns sowie den Planerinnen und Planern zu der Zugzahlungsprognose 2030, der Methodik zur Entwicklung von Linienkorridoren","downloads": [{"type": "PDF", "name": "2. Themenworkshops Präsentation"},{"type": "PDF", "name": "2. Themenworkshop Protokollentwurf"}, {"type": "PDF", "name": "2. Themenworkshop Protokollentwurf"}], "link": "https://www.google.com", "type": "download"}]';

	let signal;

	function fetchData(value) {
		setTimeout(() => {
			createSearchResult(JSON.parse(testData));
		}, 500);
		return;
		if (signal) {
			signal.abort();
		}
		if ('AbortController' in window) {
			const controller = new AbortController();
			signal = controller.signal;
		}
		fetch(`/suche.json?q=${encodeURIComponent(value)}`, { signal })
			.then(response => response.json())
			.then(createSearchResult)
			.catch(error => {});
	}

	function createSearchResult(parsedData) {
		resultsWrapper.querySelector('.js-loading').classList.remove('loading');
		let contentPages = '';
		let contentEvents = '';
		let contentProjectEntries = '';
		let contentDownloads = '';
		numPages = 0;
		numEvents = 0;
		numProjectEntries = 0;
		numDownloads = 0;
		parsedData.forEach(element => {
			resultCategory.forEach(elem => {
				if(elem.classList.contains(element.type)) {
					switch (element.type) {
						case "page":
							contentPages += generateSearchResultContent(element, element.downloads.length);
							break;
						case "event":
							contentEvents += generateSearchResultContent(element, element.downloads.length);
							break;
						case "projectEntry":
							contentProjectEntries += generateSearchResultContent(element, element.downloads.length);
							break;
						case "download":
							contentDownloads += generateSearchResultContent(element, element.downloads.length);
							break;
					}
				}
			})
			tabs.forEach(elem => {
				if(elem.classList.contains(element.type)) {
					switch(element.type) {
						case "page":
							elem.dataset.results = numPages;
							break;
						case "event":
							elem.dataset.results = numEvents;
							break;
						case "projectEntry":
							elem.dataset.results = numProjectEntries;
							break;
						case "download":
							elem.dataset.results = numDownloads;
							break;
					}
				}
			})
		})

		setNumberOfResults();

		const pagesWrapper = resultsWrapper.querySelector('.page-content');
		const eventsWrapper = resultsWrapper.querySelector('.event-content');
		const projectEntriesWrapper = resultsWrapper.querySelector('.projectEntry-content');
		const downloadsWrapper = resultsWrapper.querySelector('.download-content');

		pagesWrapper.innerHTML = contentPages;
		eventsWrapper.innerHTML = contentEvents;
		projectEntriesWrapper.innerHTML = contentProjectEntries;
		downloadsWrapper.innerHTML = contentDownloads;

		if(pagesWrapper.innerHTML != "") {
			pagesWrapper.parentElement.classList.remove('is-hidden');
		}
		if(eventsWrapper.innerHTML != "") {
			eventsWrapper.parentElement.classList.remove('is-hidden');
		}
		if(projectEntriesWrapper.innerHTML != "") {
			projectEntriesWrapper.parentElement.classList.remove('is-hidden');
		}
		if(downloadsWrapper.innerHTML != "") {
			downloadsWrapper.parentElement.classList.remove('is-hidden');
		}
	}

	function generateSearchResultContent(content, numResults) {
		switch (content.type) {
			case "page":
				numPages++;
				break;
			case "event":
				numEvents++;
				break;
			case "projectEntry":
				numProjectEntries++;
				break;
			case "download":
				numDownloads++;
				break;
		}

		if(content.downloads) {
			if(numResults === 1) {
				return `
				<a href="${content.link}" class="search__results__items__item">
					<span class="search__results__items__item__path">${content.path}</span>
					<h3 class="search__results__items__item__title">${content.title}</h3>
					<p class="search__results__items__item__text">${content.text}</p>
					<div class="search__results__items__item__downloads">
						<div>
							<span>${content.downloads[0].type}</span>
							<span>${content.downloads[0].name}</span>
						</div>
					</div>
					<span class="linkbutton">Weiterlesen</span>
				</a>
				`;
			} else if (numResults === 2) {
				return `
				<a href="${content.link}" class="search__results__items__item">
					<span class="search__results__items__item__path">${content.path}</span>
					<h3 class="search__results__items__item__title">${content.title}</h3>
					<p class="search__results__items__item__text">${content.text}</p>
					<div class="search__results__items__item__downloads">
						<div>
							<span>${content.downloads[0].type}</span>
							<span>${content.downloads[0].name}</span>
						</div>
						<div>
							<span>${content.downloads[1].type}</span>
							<span>${content.downloads[1].name}</span>
						</div>
					</div>
					<span class="linkbutton">Weiterlesen</span>
				</a>
				`;
			} else {
				return `
				<a href="${content.link}" class="search__results__items__item">
					<span class="search__results__items__item__path">${content.path}</span>
					<h3 class="search__results__items__item__title">${content.title}</h3>
					<p class="search__results__items__item__text">${content.text}</p>
					<div class="search__results__items__item__downloads multiple-downloads">
						<div>
							<span>${content.downloads[0].type}</span>
							<span>${content.downloads[0].name}</span>
						</div>
						<div>
							<span>${content.downloads[1].type}</span>
							<span>${content.downloads[1].name}<span class="more-downloads">+${content.downloads.length - 2}</span></span>
						</div>
					</div>
					<span class="linkbutton">Weiterlesen</span>
				</a>
				`;
			}
		}
	}
	*/
}


//
// Lärmschutz
//
const noiseCalibration = $('.js-calib');
if (noiseCalibration) {
	const firstStep = $('.first-step', noiseCalibration);
	const videoStep1 = $('.video-step-1', noiseCalibration);
	const videoStep2 = $('.video-step-2', noiseCalibration);
	const videoStep3 = $('.video-step-3', noiseCalibration);

	const calibBtnNext = $('.btn.next-step', noiseCalibration);
	const calibBtnRepeat = $('.btn.repeat', noiseCalibration);

	const calibTabsContainer = $('.tabs-container', noiseCalibration);

	// Start calibration
	$('.js-calib-start', noiseCalibration).addEventListener('click', () => {
		videoStep1.classList.add('hidden');
		videoStep2.classList.remove('hidden');
		firstStep.classList.add('hidden');
		videoStep2.play();

		setTimeout(function() {
			calibBtnNext.classList.remove('hidden');
			calibBtnRepeat.classList.remove('hidden');
		}, 3000);

		setTimeout(function() {
			calibBtnRepeat.classList.remove('opacity-hidden');
		}, 6000);
	}, false);

	// Repeat calibration
	$('.js-calib-repeat', noiseCalibration).addEventListener('click', () => {
		calibBtnNext.classList.add('hidden');
		calibBtnRepeat.classList.add('hidden');

		videoStep2.currentTime = 0;
		videoStep2.play();

		setTimeout(function() {
			calibBtnNext.classList.remove('hidden');
			calibBtnRepeat.classList.remove('hidden');
		}, 3000);

		setTimeout(function() {
			calibBtnRepeat.classList.remove('opacity-hidden');
		}, 6000);
	}, false);

	// Next calibration step
	$('.js-calib-next', noiseCalibration).addEventListener('click', () => {
		videoStep2.pause();

		calibBtnNext.classList.add('hidden');
		calibBtnRepeat.classList.add('hidden');

		videoStep2.classList.add('hidden');
		videoStep3.classList.remove('hidden');
		videoStep3.play();

		calibTabsContainer.classList.remove('hidden');

		setTimeout(function() {
			videoStep3.parentNode.classList.add('pointer');
			playVideoWithCurrentState();
		}, 3000);
	}, false);

	/* Tabs */
	const laermTabs = $All('.tab-header', calibTabsContainer);
	const laermTabContent = $All('.tab-content', calibTabsContainer);

	laermTabs.forEach((tab) => {
		tab.addEventListener('click', () => {
			const isActiveTab = tab.classList.contains('active');

			/* Close all tabs */
			laermTabContent.forEach((content) => {
				content.classList.remove('active');
			});

			laermTabs.forEach((tab) => {
				tab.classList.remove('active');
			});

			if (!isActiveTab) {
				/* Open this tab */
				tab.classList.add('active');
				tab.nextElementSibling.classList.add('active');
			}
		});
	});

	const subTabs = $All('.tab-choose', noiseCalibration);
	const fluesterbremse = $('.js-fluesterbremse', noiseCalibration);
	const laermState = {
		zug: 'gz',
		bremse: 'gg',
		wand: 'sw0',
	};

	subTabs.forEach ((tab) => {
		tab.addEventListener('click', () => {
			/* Remove current active */
			for (let sibling of tab.parentNode.children) {
				if (sibling !== tab) sibling.classList.remove('active');
			}

			tab.classList.add('active');

			/* change main tab*/
			const choosenName = $('strong', tab).innerHTML;
			const choosenImg = $('.tab-icon', tab).innerHTML;

			const parentTab = $('.tab-header', tab.parentNode.parentNode);
			$('.tab-icon', parentTab).innerHTML = choosenImg;
			$('strong', parentTab).innerHTML = choosenName;


			/* set state */
			laermState[tab.dataset.category] = tab.dataset.value;
			if (tab.dataset.category === 'zug') {
				laermState.zug = tab.dataset.value;

				/* if ice no bremse */
				if (tab.dataset.value === 'ice') {
					fluesterbremse.classList.add('deactivate');
					fluesterbremse.classList.remove('active');

					/* set other bremse */
					if (laermState.bremse === 'fb') {
						laermState.bremse = 'gg';
						$('.js-hauptbremse .tab-icon', noiseCalibration).innerHTML = $('.js-normalebremse .tab-icon', noiseCalibration).innerHTML;
						$('.js-hauptbremse strong', noiseCalibration).innerHTML = $('.js-normalebremse strong', noiseCalibration).innerHTML;
						$('.js-normalebremse', noiseCalibration).classList.add('active');
					}
				} else {
					fluesterbremse.classList.remove('deactivate');
				}
			}

			/* play Video */
			playVideoWithCurrentState();
		});
	});

	videoStep3.parentNode.addEventListener('click', () => {
		if (!videoStep3.classList.contains('hidden')) {
			if (videoStep3.paused) {
				videoStep3.play();
			} else {
				videoStep3.pause();
			}
		}
	}, false);

	function playVideoWithCurrentState() {
		const videoSource = $('source', videoStep3);
		const path = '/videos/laerm/'
		let newVideoSrc;
		// let newVideoSrc = path + '03_Laermgrafik_Aufbau_Kreis_bereits_da.mp4';

		if (laermState.zug === "ice") {
			newVideoSrc = path + 'Laermgrafik_ice_' + laermState.wand + '.mp4'
		} else {
			newVideoSrc = path + 'Laermgrafik_gv_' + laermState.bremse + '_' + laermState.wand + '.mp4'
		}

		videoSource.src = newVideoSrc;
		videoStep3.load();
		videoStep3.play();
	}
}
